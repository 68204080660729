@import 'shared/styling/_variables';

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	src: url('./common/fonts/Raleway/Raleway-VariableFont_wght.ttf')
		format('truetype');
}

@font-face {
	font-family: 'Raleway';
	font-style: italic;
	src: url('./common/fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf')
		format('truetype');
}

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: 'Raleway', Arial;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #282828;
	background-color: #ffffff;
}

code {
	font-family: 'Raleway', Arial;
}

.pointer {
	cursor: pointer;
}

/*-------Circle spinner------*/

#circle-spinner {
	border: 2px rgba(251, 251, 251, 0.603) solid;
	border-top: 2px #de996f solid;
	border-left: 3px #de996f solid;
	border-bottom: 3px #de996f solid;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	-webkit-animation: circle-spinner-animation 1s infinite linear;
	animation: circle-spinner-animation 1s infinite linear;
	margin-left: auto;
	margin-right: auto;
}

@keyframes circle-spinner-animation {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

/*-------Loading spinner three dots------*/
#loading-spinner {
	color: $primary-warmgray-2;
	font-size: 4px;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translate(80px, -10px);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	@include loadingAnim();

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		@include loadingAnim();
	}

	&:before {
		left: -3.5em;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	&:after {
		left: 3.5em;
	}
}

body.modal-open {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.modal {
	/*-------General Modal Styling eg. IdleTimer and OutOfService------*/
	.modal-dialog {
		top: 10%;

		&.outOfService {
			top: 0;
		}
	}

	.logo {
		width: unset;
	}
	.close {
		font-size: 3.5rem;
		padding-top: 0;
		padding-bottom: 0;
	}
	.modal-content {
		border: none;
		padding: 1rem;
		.modal-header {
			border-bottom: none;
		}
		.modal-footer {
			border-top: none;
		}
	}
	.btn {
		width: unset;
	}
	.btn.btn-primary {
		font-size: 1rem;
		@include btnPrimaryBronze;
	}

	&.idletimeout-modal {
		.loading {
			margin-bottom: 1rem;
			font-size: 7px;
		}
	}

	&.sdd-disable-mandate-modal {
		.btn {
			width: 100%;
			padding: 0.6rem 2rem !important;
		}

		.modal-title {
			font-weight: 900;
			font-size: 1.7rem;
			text-align: center;
		}
	}

	/*-------Information Modal------*/
	&.information-modal {
		.title-text {
			font-size: 22px;
			margin-top: 1rem;
		}

		.body-text {
			font-size: 16px;
		}

		.modal-footer {
			justify-content: center;
		}

		.linkColor {
			color: #de996f;
		}

		//Specific css for DE marketing ref. friends
		&.mc-marketing {
			.modal-dialog {
				max-width: 468px;

				.close {
					font-size: 3rem;
					padding: 0 1rem;
					margin-bottom: 0;
					margin-top: -2rem;
					margin-right: -2rem;
				}

				.modal-content {
					background-color: $primary-warmgray-2;
				}
				.modal-body {
					padding-top: 0;
				}

				.modal-header {
					flex-direction: column;
					flex-direction: column-reverse;

					.modal-head {
						display: flex;
						flex-direction: column-reverse;
						width: 100%;
					}

					.header-image {
						justify-content: center;
						width: 100%;

						img {
							max-width: 100%;
						}
					}
				}
				.modal-footer {
					a {
						text-decoration: none;
					}
				}
			}

			.refer-friends-subtitle {
				font-size: 18px;
				margin-bottom: 1.5rem;
			}

			//Marketing campaign for Ryd Aktion - may 2023
			&.ryd-aktion {
				.header-image img {
					border-radius: 5px;
				}
				@media screen and (min-width: $screen-sm) {
					.title-text {
						max-width: 95%;
					}
				}
			}
		}
	}
}

/*-------Tooltip------*/
.tooltip-tip {
	width: 15px;
	height: 15px;
	&::before {
		content: url('/img/icons/tooltip-bronze.svg');
	}
	.tooltip-text {
		width: 190px;
		color: $primary-warmgray-2;
		background-color: $primary-bronze-1;
		border: 1px solid $primary-bronze-1;
		border-radius: 4px;
		font-size: 0.8rem;
		font-weight: 100;
		padding: 15px;
		text-align: left;
		margin-left: -95px;
		position: absolute;
		left: 800%;
		top: -110%;

		.arrow-down {
			width: 0;
			height: 0;
			border-top: 10px solid $primary-bronze-1;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			margin: 0;
			position: absolute;
			right: 98%;
			bottom: 74%;
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			transform: rotate(90deg);
		}
	}
}
.box-info {
	@include boxInformation;
}

.noscroll {
	overflow: hidden;
}
