﻿// Primary colors
$primary-bronze-1: #de996f;
$primary-warmgray-2: #f6f5ee; // a.k.a Cream
$primary-warmgray-5: #d0cfca;
$primary-warmgray-9: #857f7b;
$primary-warmgray-11: #575651;

// Secondary
$bronze-light: #eea87e;
$bronze-red: #c87d5a;
$stonegray-10: #7e7f7f;
$stonegray-20: #706f6f;
$cream-light: #f9f8f3;
$cream-dark: #e9e8da;
$cream-rich: #efeee3;

// Functional colors
$black: #000000;
$white: #ffffff;
$danger: #8d191d;
$green-md: #1f9245;
$green: #0e4a22;
$brown: #a26d23;
$purple: #50145c;
$blue: #0e204b;

// Special
$gradient-bronze: linear-gradient(
	90deg,
	#dc8c69 0%,
	#c87d5a 20%,
	#dc8c69 60%,
	#eea87e 100%
);

// Shadows (Card has its own also)
$shadow-none: none;
$shadow-1: 0 0 4rem 0 rgba(65, 65, 65, 0.15);
$shadow-2: 0 0 1.5rem -0.2rem rgba(89, 89, 89, 0.5);
$shadow-3: 0 0 3.5rem 0.1rem rgba(65, 65, 65, 0.5);
$shadow-4: 0 0 2rem 0.1rem rgba(65, 65, 65, 0.25);
$shadow-5: 0 0 0 0.15rem $primary-bronze-1;
$shadow-6: 0 1.9rem 1.9rem -1.6rem rgba(107, 107, 107, 1);
$shadow-7: 0 0 0 1.5rem $white, 0 -8rem 0 1.5rem $white,
	0 0 19rem 1rem rgba(0, 0, 0, 0.4);

// Font sizes
$font-size-h1: 4.2rem;
$font-size-h2: 4rem;
$font-size-h3: 3.6rem;
$font-size-h4: 3.6rem;
$font-size-h5: 3rem;
$font-size-h6: 2.8rem;

$font-size-body: 1.7rem;
$font-size-subtitle: 2rem;
$font-size-overline: 2rem;
$font-size-small: 1.3rem;
$font-size-large: 1.9rem;
$font-size-xlarge: 2.8rem;

// Border radius
$border-radius-base: 0.4rem;
$border-radius-small: 0.8rem;
$border-radius-medium: 1rem;
$border-radius-large: 2rem;

// General Y-axis paddings. Typical usage: top and bottom padding for consistency among all <section> and alike containers.
$general-padding-1: 2rem;
$general-padding-2: 4rem;
$general-padding-3: 6rem;

//== Media queries breakpoints
// Minimums!
$screen-xs: 0px; // Funny, but for consistency.
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1440px;

//Ipad & tablet max logical height
$screen-tablet-sm: 768px;
$screen-tablet-md: 1024px; //Width min 768
$screen-tablet-lg: 1366px; //Width min 1024

// So media queries don't overlap when required, provide a maximum. -0.02 like Bootstrap does it(!)
$screen-xs-max: ($screen-sm - 0.02); //575.98px
$screen-sm-max: ($screen-md - 0.02); //767.98px
$screen-md-max: ($screen-lg - 0.02); //991.98px
$screen-lg-max: ($screen-xl - 0.02); //1199.98px
$screen-xl-max: ($screen-xxl - 0.02); //1439.98px

//== Container sizes
$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;

@function ifImportant($important) {
	@return #{if($important, '!important', '')};
}

@mixin transitionBase($important: false) {
	transition: all 398ms ease-in-out ifImportant($important);
}

@mixin shadow($value: $shadow-1, $important: false) {
	-webkit-box-shadow: $value ifImportant($important);
	-moz-box-shadow: $value ifImportant($important);
	box-shadow: $value ifImportant($important);
}

@mixin noOutlineOnHoverEtc {
	&:hover,
	&:focus,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		outline: none !important;
		box-shadow: none;
	}
}

@mixin ripple($hoverColor, $clickColor, $speed: 800ms) {
	&:not(:disabled):not(.disabled) {
		background-position: center !important;
		transition: background $speed !important;

		&:hover {
			background: $hoverColor
				radial-gradient(circle, transparent 0.5%, $hoverColor 1%)
				center/20000%;
		}

		&:active {
			background-color: $clickColor !important;
			background-size: 100% !important;
			transition: background 0s !important;
		}
	}
}

@mixin mobileOneColumn {
	> .row {
		&:nth-child(3) {
			> div {
				margin-bottom: 3rem;
			}
		}

		.col-6 {
			flex: 0 1 100%;
			max-width: 100%;

			@media (min-width: 768px) {
				flex: 0 0 50%;
				max-width: 50%;
				margin-bottom: 0;
			}

			&.form-box:not(:last-child) {
				margin-bottom: 1rem;

				@media (min-width: 768px) {
					margin-bottom: 0;
				}
			}

			.control-checkbox {
				margin: 0;
			}
		}
	}

	@media (min-width: 425px) and (max-width: $screen-sm-max) {
		> .row {
			margin: 0 auto;
			max-width: 380px;
		}
	}
}

@mixin col-2 {
	-ms-flex: 0 0 16.666667% !important;
	flex: 0 0 16.666667% !important;
	max-width: 16.666667%;
}

@mixin col-6 {
	-ms-flex: 0 0 50% !important;
	flex: 0 0 50% !important;
	max-width: 50%;
}

@mixin col-8 {
	-ms-flex: 0 0 66.666667% !important;
	flex: 0 0 66.666667% !important;
	max-width: 66.666667%;
}

@mixin col-12 {
	-ms-flex: 0 0 100% !important;
	flex: 0 0 100% !important;
	max-width: 100%;
}

@mixin bronzeGradient($important: false) {
	background: $gradient-bronze ifImportant($important);
}

@mixin fontBase {
	font-family: 'Raleway', Arial;
	font-size: $font-size-body;
	font-weight: 400;
	color: $black;
	margin: 0;
}

@mixin buttonArrowBase($important: false) {
	background-repeat: no-repeat ifImportant($important);
	content: '' ifImportant($important);
	position: relative ifImportant($important);
	display: inline-block ifImportant($important);
	background-size: 100% ifImportant($important);
	width: 16px ifImportant($important);
	height: 16px ifImportant($important);
}

@mixin buttonArrowPosition($before: true, $important: false) {
	@if $before {
		top: 0.2rem ifImportant($important);
		right: 0.5rem ifImportant($important);
		transform: rotate(180deg);
	} @else {
		left: 0.5rem ifImportant($important);
	}
}

@mixin dropdownArrow {
	content: url('/img/dropdown_arrow_tf21.svg');
	border: none;
	position: absolute;
	right: 0.7rem;
	top: 1.5rem;
	margin: 0;
	width: 18px;
	height: 18px;
	transform: rotate(0deg);
	@include transitionBase;
}

@mixin btnBase($important: false) {
	display: inline-block ifImportant($important);
	margin: 0 auto ifImportant($important);
	padding: 1rem 2.4rem ifImportant($important);
	font-size: 1.6rem ifImportant($important);
	font-weight: 600 ifImportant($important);
	border-width: 0.1rem ifImportant($important);
	border-radius: $border-radius-small ifImportant($important);
	border-style: solid ifImportant($important);

	@media screen and (min-width: $screen-md) {
		padding: 1rem 3.2rem ifImportant($important);
	}
}

@mixin btnPrimaryBronze($important: false) {
	background-color: $primary-bronze-1 ifImportant($important);
	border-color: $primary-bronze-1 ifImportant($important);
	color: $primary-warmgray-2 ifImportant($important);

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: $bronze-light ifImportant($important);
			border-color: $bronze-light ifImportant($important);
		}
	}
}

@mixin btnPrimaryDarkGray($important: false) {
	background-color: $primary-warmgray-11 ifImportant($important);
	border-color: $primary-warmgray-11 ifImportant($important);
	color: $primary-warmgray-2 ifImportant($important);

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: #6b6a64 ifImportant($important);
			border-color: #6b6a64 ifImportant($important);
		}
	}
}

@mixin btnPrimaryLightGray($important: false) {
	background-color: $primary-warmgray-2 ifImportant($important);
	border-color: $primary-warmgray-2 ifImportant($important);
	color: $primary-warmgray-11 ifImportant($important);

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: $white ifImportant($important);
			border-color: $white ifImportant($important);
		}
	}
}

@mixin btnOutlineBronze($important: false) {
	background-color: transparent ifImportant($important);
	border-color: $primary-bronze-1 ifImportant($important);
	color: $primary-bronze-1 ifImportant($important);

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: rgba(238, 168, 126, 0.2) ifImportant($important);
		}
	}
}

@mixin btnOutlineDarkGray($important: false) {
	background-color: transparent ifImportant($important);
	border-color: $primary-warmgray-11 ifImportant($important);
	color: $primary-warmgray-11 ifImportant($important);

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: rgba(136, 136, 132, 0.2) ifImportant($important);
			color: rgba(87, 86, 81, 0.95) ifImportant($important);
		}
	}
}

@mixin btnOutlineLightGray($important: false) {
	background-color: transparent ifImportant($important);
	border-color: $primary-warmgray-2 ifImportant($important);
	color: $primary-warmgray-2 ifImportant($important);

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: rgba(246, 245, 238, 0.2) ifImportant($important);
		}
	}
}

@mixin btnOutlineDanger($important: true) {
	background-color: transparent ifImportant($important);
	border-color: $danger ifImportant($important);
	color: $danger ifImportant($important);

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			color: rgba(141, 25, 29, 0.7) ifImportant($important);
			border-color: rgba(141, 25, 29, 0.5) ifImportant($important);
		}
	}
}

@mixin btnSecondaryDarkGray($important: false, $arrowBefore: true) {
	background-color: transparent ifImportant($important);
	border-color: transparent ifImportant($important);
	color: $primary-warmgray-11 ifImportant($important);

	&:after {
		margin-left: 1.3rem;
		@include buttonArrowBase($important);
		@include buttonArrowPosition($arrowBefore, $important);
		background-image: url('/img/arrowRight_darkGrey.svg')
			ifImportant($important);
	}

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: rgba(87, 86, 81, 0.5) ifImportant($important);
			color: $white ifImportant($important); //THOTHOHOSHTEOSAElkasdjhfksj

			&:after {
				filter: invert(100%) brightness(2);
			}
		}
	}
}

@mixin btnSecondaryLightGray($important: false, $arrowBefore: true) {
	background-color: transparent ifImportant($important);
	border-color: transparent ifImportant($important);
	color: $primary-warmgray-2 ifImportant($important);

	&:after {
		@include buttonArrowBase($important);
		@include buttonArrowPosition($arrowBefore, $important);
		background-image: url('/img/arrowRight_lightGrey.svg')
			ifImportant($important);
	}

	&:not(:disabled):not(.disabled) {
		&.active,
		&:active,
		&:focus,
		&:hover {
			background-color: rgba(246, 245, 238, 0.2) ifImportant($important);
		}
	}
}

@mixin linkBronze($important: false) {
	color: $primary-bronze-1 ifImportant($important);

	&:hover {
		color: $primary-warmgray-11 ifImportant($important);
		text-decoration: underline ifImportant($important);
		text-decoration-color: $primary-warmgray-11 ifImportant($important);
	}
}

@mixin linkGray2($important: false) {
	color: $primary-warmgray-2 ifImportant($important);

	&:hover {
		color: $primary-warmgray-2 ifImportant($important);
		text-decoration: underline ifImportant($important);
		text-decoration-color: $primary-warmgray-2 ifImportant($important);
	}
}

@mixin linkGray11($important: false) {
	color: $primary-warmgray-11 ifImportant($important);

	&:hover {
		color: $primary-warmgray-11 ifImportant($important);
		text-decoration: underline ifImportant($important);
		text-decoration-color: $primary-warmgray-11 ifImportant($important);
	}
}

@mixin linkWhite($important: false) {
	color: $white ifImportant($important);

	&:hover {
		color: $primary-warmgray-5 ifImportant($important);
		text-decoration: underline ifImportant($important);
		text-decoration-color: $primary-warmgray-5 ifImportant($important);
	}
}

@mixin filterGray11 {
	filter: brightness(55%) sepia(95%) invert(40%) brightness(73%); // Very close to warm-gray-11
}

@mixin invalid {
	background-image: none;
	color: $danger;
	border-color: $danger;
}

@mixin transparentNoBorder {
	background: transparent;
	border: 0;
}

@mixin fieldFocus {
	color: $primary-warmgray-11;
	border-color: $primary-bronze-1 !important;
	outline: 0;
	@include shadow($shadow-5);
}

/* --- Loading spinner styling for buttons --- */
@mixin loadingAnim {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
}

/* --- Loading spinner (for buttons) animation --- */
@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
@-webkit-keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}

	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
/* --- end --- */

@mixin checkbox {
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: inherit;
	align-self: center;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	input:checked ~ .checkmark {
		content: url('/img/icons/tick_bronze.svg');
	}
	input:disabled {
		opacity: 0.2;
	}
	.checkmark {
		position: absolute;
		top: 3px;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: #fff;
		border: 0.1rem solid #575651;
		border-radius: 0.15rem;
	}
}

@mixin checkboxLarge {
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 1.7rem;
	align-self: center;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	input:checked ~ .checkmark {
		content: url('/img/icons/tick_bronze.svg');
	}
	input:disabled {
		opacity: 0.2;
	}
	.checkmark {
		position: absolute;
		top: 3px;
		left: 0;
		height: 30px;
		width: 30px;
		background-color: #fff;
		border: 0.1rem solid #575651;
		border-radius: $border-radius-base;
	}

	.text {
		margin-left: 15px;
	}
}

//Used for messages in eg. application responses and/or errors
@mixin boxInformation {
	background-color: #ece9e1;
	position: relative;
	min-height: 58px;
	padding: 37px 30px 37px 80px;
	margin-top: 2rem;
	width: 100%;

	&.info {
		&::before {
			content: '';
			background: url('/img/icons/info_bronze.svg');
			display: inline-block;
			background-size: cover;
			width: 25px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.info-gray {
		&::before {
			content: '';
			background: url('/img/icons/info_gray.svg');
			display: inline-block;
			background-size: cover;
			width: 25px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.success-msg-checkmark-animation {
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;
		align-items: center;
		padding-bottom: 30px;
		padding: 0 0 30px 0;
		text-align: center;

		.success-img-box {
			margin: 30px 0;
			border: 3px solid #1f9245;
			border-left-color: #1f9245;
			position: relative;
			display: inline-block;
			vertical-align: top;
			border-radius: 50%;
			width: 120px;
			height: 120px;
		}

		.checkmark {
			&.draw:after {
				animation-duration: 1.3s;
				animation-timing-function: ease;
				animation-name: success-checkmark;
				transform: scaleX(-1) rotate(135deg);
			}
			&:after {
				opacity: 1;
				height: 60px;
				width: 30px;
				transform-origin: left top;
				border-right: 5px solid #1f9245;
				border-top: 5px solid #1f9245;
				content: '';
				left: 26px;
				top: 60px;
				position: absolute;
			}
		}
	}

	&.success {
		position: relative;
		min-height: 58px;
		margin: 1rem 0;
		min-height: 94px;

		&::before {
			content: '';
			background: url('/img/icons/tick_bronze.svg');
			display: inline-block;
			background-size: cover;
			width: 31px;
			height: 21px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.fail {
		color: $danger;
		margin: 1rem auto 2.4rem auto;
		position: relative;
		min-height: 58px;

		&::before {
			content: '';
			background: url('/img/icons/info-icon-error-darkred.svg');
			display: inline-block;
			background-size: cover;
			width: 25px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.post {
		padding: 37px 10px 37px 90px;
		margin: 1rem 0;
		min-height: 94px;

		&::before {
			content: '';
			background: url('/img/icons/post-gray.svg');
			display: inline-block;
			background-size: cover;
			width: 35px;
			height: 25px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}

	&.box-info-button {
		padding: 37px 10px;
		margin: 2rem;
		border-radius: $border-radius-base;
		display: flex;
		flex-direction: column;

		.message-container {
			padding: 0 25px 0 75px;
			&::before {
				content: '';
				background: url('/img/icons/info_bronze.svg');
				display: inline-block;
				background-size: cover;
				width: 35px;
				height: 35px;
				margin: 0;
				position: absolute;
				top: 25%;
				left: 30px;
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}

		.button-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 30px;
			button {
				width: 90%;
				max-width: 360px;
			}
		}
	}
}

/* Error fallback container styling for the ErrorFallbackPage */
/* Container wrapping box-info component */

.error-fallback-container {
	display: flex;
	justify-content: center;
	margin: 3rem auto;
	max-width: 670px;
}

// Checkmark success animation
@keyframes success-checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	20% {
		height: 0;
		width: 30px;
		opacity: 1;
	}
	40% {
		height: 60px;
		width: 30px;
		opacity: 1;
	}
	100% {
		height: 60px;
		width: 30px;
		opacity: 1;
	}
}

//Styling slider track in formbuilder slider component
@mixin track {
	background: transparent;
	border: none;
	height: 0;
	width: 100%;
	border-radius: 6px;
}
